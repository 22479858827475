import { Component, OnInit, ViewChild, NgModule, ElementRef } from '@angular/core';
import { OfertaDia } from './oferta-dia'
import { Produto } from './produto'
import { OfertaDataService } from './oferta-data.service'



@Component({
  selector: 'app-ofertas',
  templateUrl: './ofertas.component.html',
  styleUrls: ['./ofertas.component.css'],
  providers: [OfertaDataService]
})

export class OfertasComponent implements OnInit {

  @ViewChild('form', {static: false}) input:ElementRef;

  public ofertaDia: OfertaDia = new OfertaDia();
  public ofertas: any;
  public addOfertaFlag: Boolean = false;
  public btnCadastrarLbl: String = 'Cadastrar'
  public brnNovaOfertaInput: String = 'Adicionar Nova Oferta';
  public btnNovaOfertaClass: String = 'btn btn-primary';
  public tipoProduto: String = '';
  public image: String = '';
  public imageError: Boolean = false;

  public fileToUpload: File = null;
  public form: any = null;

  constructor(private ofertaService: OfertaDataService) {}

  async ngOnInit() {
    this.ofertas = await this.ofertaService.getAllOfertas();
  }

  addProduto() {
    var produto = new Produto();
    if(this.ofertaDia.produtos.length == 0){
        produto.id = 1;
    } else {
        produto.id = Math.max.apply(Math,this.ofertaDia.produtos.map(function(o){return o.id;})) + 1;
        produto.idOferta = this.ofertaDia.id;
    }

    this.ofertaDia.produtos.push(produto);
  }


  removeSubmited() {
    if(this.form != null)
      this.form.submitted = false;
  }

  addOferta() {

      this.removeSubmited();
      this.addOfertaFlag      = !this.addOfertaFlag;
      this.ofertaDia          = new OfertaDia();
      this.btnCadastrarLbl    = 'Cadastrar';

      this.image = '';

      if(this.addOfertaFlag) {
          this.brnNovaOfertaInput = 'Cancelar';
          this.btnNovaOfertaClass = 'btn btn-danger'
      } else {
          this.brnNovaOfertaInput = 'Adicionar Nova Oferta';
          this.btnNovaOfertaClass = 'btn btn-primary'
      }
  }



  async adicionaOferta(obj) {

    this.ofertaDia.produtos = [this.ofertaDia.produto];
    var response = this.ofertaDia;
    response.produtos = [this.ofertaDia.produto];

    console.log('VAMOS VER O QUE VEM AQUI', this.ofertaDia);

    this.form = obj;

    if(obj.valid) {

      console.log('entrei aqui povo');
      if(this.ofertaDia.id) {
        this.addOfertaFlag = false;
        this.brnNovaOfertaInput = 'Adicionar Nova Oferta';
        this.btnNovaOfertaClass = 'btn btn-primary'
        try {
          await this.ofertaService.updateOferta(response);
        } catch(e) {
          console.log(e);
          alert('Erro ao atualizar ofertas');
          return;
        }
      } else {
        try {
            await this.ofertaService.adicionaOferta(response);
        } catch (e) {
          console.log(e);
          alert('Erro ao inserir ofertas');
          return;
        }

        this.addOfertaFlag = false;
        this.brnNovaOfertaInput = 'Adicionar Nova Oferta';
      }
    }
    this.image = '';
    this.ofertas = await this.ofertaService.getAllOfertas();

  }


  removeProduto(obj) {

    this.ofertaDia.produtos = this.ofertaDia.produtos
          .filter(tp => tp.id != obj);
  }

  async removeOferta(obj, event) {

    event.stopPropagation();
    await this.ofertaService.removeOferta(obj);
    this.ofertas = await this.ofertaService.getAllOfertas();

  }


  editarOferta(obj) {

    this.removeSubmited();
    this.addOfertaFlag = true;
    this.ofertaDia = JSON.parse(JSON.stringify(obj));
    this.ofertaDia.produto = this.ofertaDia.produtos[0];
    this.btnCadastrarLbl = 'Editar Elemento';
    this.image = this.ofertaDia.image;

  }


  readURL(input) {

    var reader = new FileReader();
    var obj = this.ofertaDia;

    reader.onload = function(e) {
      obj.image = reader.result;
    }

    this.image = obj.image;
    reader.readAsDataURL(input);
  }

  handleFileInput(files: FileList, event) {

      if(files[0].size > (200 * 1024)) {
          event.stopPropagation();
          this.imageError = true;
      } else {
        this.imageError = false;
      }

      this.readURL(files[0]);
  }
}
