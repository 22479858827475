import { Produto } from './produto'

export class OfertaDia {

  public id:              Number = 0;
  public image:           any = '';
  public nome:            String = '';
  public descricao:       String = '';
  public dataIniVigencia: String = '';
  public dataFimVigencia: String = '';

  public telefoneContato: String = '';
  public link:            String = '';

  public produto:         Produto =  new Produto();


  public produtos:    Produto[] = [ new Produto()];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}
