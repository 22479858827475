export class Push {

  public id: Number = 0;
  public tipoEnvio: String = "";
  public para : String = "";
  public titulo : String = "";
  public mensagem : String = "";

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
