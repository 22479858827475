import { NgForm } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { PushNotificationDataService } from "./push-notification-data.service";
import { Push } from "./push";

@Component({
  selector: "app-push-notification",
  templateUrl: "./push-notification.component.html",
  styleUrls: ["./push-notification.component.css"],
  providers: [PushNotificationDataService]
})
export class PushNotificationComponent implements OnInit {
  public submited: String = "";
  public push: Push = new Push();
  public enviarPara = "";

  constructor(private pushService: PushNotificationDataService) {}

  ngOnInit() {
    console.log("estou aqui");
  }

  async submitPush(obj) {
    this.submited = "submited";
    await this.pushService.sendPush(this.push);
    await this.pushService.insertPushHistory(this.push);
    alert("Push enviado com sucesso");
  }
}
