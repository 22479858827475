import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private service: LoginService) { }

  usuario:    String = '';
  password:   String = '';

  ngOnInit() {
    localStorage.removeItem("logado");
  }

  async login() {

    try {

      let loginObj = await this.service.loginUser({"nome": this.usuario, "senha": this.password});
      
      localStorage.setItem("logado", "true");
      localStorage.setItem("token", loginObj.token);
      localStorage.setItem("permissoes", loginObj.permissoes);
      this.router.navigate(['blank']);


    } catch (e) {
      console.log(e);
      alert('Usuário ou senha inválido');
    }

  }

}
