import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { Config } from '../config/config'

@Injectable()
export class LoginService {

  constructor(private http:HttpClient) { }
  private config: Config = new Config();

  loginUser(obj) : any {
      return this.http.post(this.config.contexto + '/authentication', obj).toPromise();
  }

}
