import { Component, OnInit } from "@angular/core";
import { UploadBackgroundService } from "./upload-background.service";

const URL = "http://localhost:7642/backgroundRf/upload";

@Component({
  selector: "app-upload-background",
  templateUrl: "./upload-background.component.html",
  styleUrls: ["./upload-background.component.css"],
  providers: [UploadBackgroundService]
})
export class UploadBackgroundComponent {
  fileToUpload: File = null;
  fileUploadSuccess = "";
  localization = "";
  loading = false;
  btnText = "Efetuar upload da imagem";
  errorUploadFile = "";

  constructor(private postFileService: UploadBackgroundService) { }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    console.log(this.fileToUpload.type);

    if (
      this.fileToUpload &&
      this.fileToUpload.type !== "image/jpeg" &&
      this.fileToUpload.type !== "image/jpg" &&
      this.fileToUpload.type !== "image/png"
    ) {
      this.errorUploadFile =
        "Formato de arquivo invalido, somente é permitido imagens do tipo JPG ou PNG.";
    } else {
      this.errorUploadFile = "";
      this.fileUploadSuccess = "";
    }
  }

  clear() {
    this.fileToUpload = null;
    this.localization = "";
    this.loading = false;
    this.btnText = "Efetuar upload da imagem";
    this.errorUploadFile = "";
  }

  async uploadFile(event: Event) {
    try {
      this.loading = true;
      this.btnText = "Efetuando upload ...";

      await this.postFileService.postFile(this.fileToUpload, this.localization);
      this.fileUploadSuccess = "Upload efetuado com sucesso!";
      this.clear();
    } catch (error) {
      this.clear();

      this.fileUploadSuccess =
        "errro ao efetuar upload, por favor tente novamente!";
    }
  }
}
