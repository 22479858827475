import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { Component, ElementRef } from "@angular/core";
import { NgModule } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  public ofertaAct: Boolean = false;
  public pushAct: Boolean = false;
  public pushHistoryAct: Boolean = false;
  public uploadBackground = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elRef: ElementRef
  ) {}

  openPage(where, event, obj) {
    this.ofertaAct = false;
    this.pushAct = false;
    this.pushHistoryAct = false;

    if (where == "push") {
      this.pushAct = true;
    } else if (where == "pushHistory") {
      this.pushHistoryAct = true;
    } else if (where === "uploadBackground") {
      this.uploadBackground = false;
    } else {
      this.ofertaAct = true;
    }

    this.router.navigate([where]);
  }

  activity() {}

  get showOfertas() {
    let perm = localStorage.getItem("permissoes");
    return perm.indexOf("OFERTAS") !== -1;
  }

  get showPush() {
    let perm = localStorage.getItem("permissoes");
    return perm.indexOf("PUSH") !== -1;
  }

  get logado() {
    return localStorage.getItem("logado");
  }
}
