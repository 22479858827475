import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OfertaDia } from "./oferta-dia";
import { Config } from "../config/config";

@Injectable()
export class OfertaDataService {
  private config: Config = new Config();

  constructor(private http: HttpClient) {}

  getAllOfertas() {
    return this.http.get(this.config.contexto + "/oferta").toPromise();
  }

  adicionaOferta(obj) {
    console.log(obj);
    return this.http.post(this.config.contexto + "/oferta", obj).toPromise();
  }

  removeOferta(id) {
    let url = this.config.contexto + "/oferta/" + id;
    return this.http.delete(url).toPromise();
  }

  updateOferta(obj) {
    return this.http.put(this.config.contexto + "/oferta", obj).toPromise();
  }
}
