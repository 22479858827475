import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Config } from '../config/config'

@Injectable()
export class PushNotificationHistoryDataService {

  private config: Config = new Config();

  constructor(private http:HttpClient) { }

  getPushHistory() : any {
    return this.http.get(this.config.contexto + '/push').toPromise();
  }

}
