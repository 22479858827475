import { RouterModule, Routes } from "@angular/router";
import { HttpClientModule } from "@angular/common/http"; // replaces previous Http service
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { OfertasComponent } from "./ofertas/ofertas.component";
import { PushNotificationComponent } from "./push-notification/push-notification.component";
import { BlankComponent } from "./blank/blank.component";
import { PushNotificationHistoryComponent } from "./push-notification-history/push-notification-history.component";
import { NgxMaskModule } from "ngx-mask";
import { AuthInterceptor } from "./auth.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { UploadBackgroundComponent } from "./upload-background/upload-background.component";

const appRoutes: Routes = [
  { path: "", component: LoginComponent },
  { path: "blank", component: BlankComponent },
  { path: "ofertas", component: OfertasComponent },
  { path: "push", component: PushNotificationComponent },
  { path: "pushHistory", component: PushNotificationHistoryComponent },
  { path: "uploadBackground", component: UploadBackgroundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OfertasComponent,
    PushNotificationComponent,
    BlankComponent,
    PushNotificationHistoryComponent,
    UploadBackgroundComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    NgxMaskModule.forRoot({}),
    BrowserModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
