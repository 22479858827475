import { environment } from "../../environments/environment";

export class Config {
  //public contexto: any = environment.hostCms;
  //public contexto: any = "http://192.168.189.60:7642";
  public contexto: any = "https://cms-backend.reservafacil.tur.br";
  public subFolder: any = "/cms/";

  constructor(values: Object = {}) {
    console.log(this.contexto);
    Object.assign(this, values);
  }
}
