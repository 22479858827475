import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Config } from '../config/config'


@Injectable()
export class PushNotificationDataService {

  private config: Config = new Config();

  constructor(private http:HttpClient) { }

  insertPushHistory(push) {
    return this.http.post(this.config.contexto + '/push', push).toPromise();
  }

  sendPush(push) {
    return this.http.post(this.config.contexto + '/push/send', push).toPromise();
  }



}
