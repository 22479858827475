export class Produto {

  public id:          Number = 0;
  public idOferta:    Number = 0;

  public tipoProduto: String = '';
  public categoria:   String = '';
  public cia:         String = '';
  public valor:       String = '';
  public moeda:       String = '';
  public origem:      String = '';
  public destino:     String = '';
  public flagTaxas:   Boolean = false;

  public flagBotaoDetalhe:   Boolean = false;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
