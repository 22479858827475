import { Component, OnInit } from '@angular/core';
import { Push } from '../push-notification/push'
import { PushNotificationHistoryDataService } from './push-notification-history-data.service'

@Component({
  selector: 'app-push-notification-history',
  templateUrl: './push-notification-history.component.html',
  styleUrls: ['./push-notification-history.component.css'],
    providers: [PushNotificationHistoryDataService]
})
export class PushNotificationHistoryComponent implements OnInit {

  public pushs: Push[] = [];

  constructor(private service : PushNotificationHistoryDataService) { }

  async ngOnInit() {
    this.pushs = await this.service.getPushHistory();
    console.log('ngOnInit', this.pushs);
  }

}
