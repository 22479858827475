import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Config } from "../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class UploadBackgroundService {
  private config: Config = new Config();

  constructor(private http: HttpClient) {}

  postFile(fileToUpload: File, localization: string) {
    const endpoint = `${this.config.contexto}/backgroundRf/upload`;
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload);
    formData.append("localization", localization);

    return this.http.post(endpoint, formData).toPromise();
  }
}
